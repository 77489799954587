import { computed, ref } from '@vue/composition-api'
import { getBrands } from '../utils/api/brands.js'
import { onlyShowDataSubjectsAssociatedWithSelectedBrand } from './configuration.js'
import { updatePrivacyPolicies } from './privacy-policies.js'

export const brandsState = ref(null)
export const allBrandsState = ref(null)

export const NO_BRAND_SELECTED = null

export const UNIVERSAL_BRAND = 0
export const UNIVERSAL_BRAND_ITEM = { value: 0, text: 'Universal' }

const selectedBrandState = ref(NO_BRAND_SELECTED)

export const userBrandIdsFilter = ref(null)

export const universalBrandItem = ref(null)

export const isMultiBrand = ref(false)

export const allBrands = ref(null)

let isLoading = false
let isLoadingPromise = null
const showAll = { value: 0, text: 'View All' }
const showAllMyBrands = { value: 0, text: 'View All My Brands' }

const load = async isCsPortal => {
	if (isLoading) return
	isLoading = true
	isLoadingPromise = getBrands()
	const { data: brandsData } = await isLoadingPromise
	universalBrandItem.value = (brandsData.brands.find(brand => brand.value === 0))
	allBrands.value = brandsData.brands
	allBrandsState.value = brandsData.brands
	isMultiBrand.value = brandsData.isMultiBrand
	brandsState.value = []
	if (isCsPortal) {
		if (userBrandIdsFilter.value.includes(UNIVERSAL_BRAND)) {
			brandsState.value.push(showAll)
		} else if (userBrandIdsFilter.value.length > 1) {
			brandsState.value.push(showAllMyBrands)
		}
	}
	brandsState.value = [...brandsState.value, ...brandsData.brands.filter(({ value }) =>
		userBrandIdsFilter.value && (userBrandIdsFilter.value.includes(value) || userBrandIdsFilter.value.includes(UNIVERSAL_BRAND)))]
	isLoading = false
}

export const waitForLoad = async () => {
	if (isLoadingPromise === null) load()
	return isLoadingPromise
}

export const loadAllBrands = async () => {
	await load()
}

export const changeSelectedBrand = brandId => {
	selectedBrandState.value = brandId
	updatePrivacyPolicies()
}

export const changeAdminPortalBrandFilter = brandId => {
	selectedBrandState.value = brandId
}

export const selectedAdminPortalBrandId = computed({
	get () {
		return selectedBrandState.value ?? null
	},
	set (value) {
		changeAdminPortalBrandFilter(value)
	}
})
export const resetBrands = () => {
	brandsState.value = null
}

export const changeUserBrandIds = userBrandIds => {
	userBrandIdsFilter.value = userBrandIds
}
export const brandOptions = computed(() => {
	if (brandsState.value === null) {
		load()
		return []
	}

	return brandsState.value
})

export const userHasBrand = brandId => {
	if (brandsState.value === null) {
		load()
	}
	return brandOptions.value.map(({ value }) => value).includes(brandId)
}

export const userHasAllBrands = brandIds => {
	if (brandsState.value === null) {
		load()
	}
	return brandIds.every(brandId => userHasBrand(brandId))
}

export const defaultBrandInSelect = computed(() => {
	if (brandsState.value === null) {
		load()
	}
	if (!clientMultiBranded.value) {
		return 0 // single brand
	}
	if (brandOptions.value.length === 1 && brandOptions.value[0].value !== 0) {
		return brandOptions.value[0].value // multi brand single brand
	}
	return null // multi brand with multiple brands or univeral
})

export const singleBrand = computed(() => {
	if (brandsState.value === null) {
		load()
	} else if (brandOptions.value.length === 1) {
		return true
	}
	return false
})

export const brandFilter = computed(() => {
	if (brandsState.value === null) {
		load()
		return []
	}
	const universal = allBrands.value.find(({ value }) => value === UNIVERSAL_BRAND)
	// non MB clients
	if (brandOptions.value.length === 1 && brandOptions.value[0].value === 0) {
		return [showAll]
	} else if (brandOptions.value.length >= 2 && !userBrandIdsFilter.value.includes(UNIVERSAL_BRAND)) {
		return [showAll, universal, ...brandOptions.value]
	} else if (brandOptions.value.length >= 2 && userBrandIdsFilter.value.includes(UNIVERSAL_BRAND)) {
		return [showAll, ...brandOptions.value]
	}
	return brandOptions
})
export const selectedBrand = computed(() => {
	if (!onlyShowDataSubjectsAssociatedWithSelectedBrand.value) return UNIVERSAL_BRAND
	if (!brandOptions.value.length) return NO_BRAND_SELECTED
	if (brandOptions.value.length === 1) return brandOptions.value[0].value
	return selectedBrandState.value
})
export const showBrandSelector = computed(() => {
	return brandOptions.value.length >= 1 && onlyShowDataSubjectsAssociatedWithSelectedBrand.value
})

export const clientMultiBranded = computed(() => {
	if (brandsState.value === null) {
		load()
		return []
	}
	return allBrands.value.length >= 2
})

export const csPortalBrandSelector = computed(() => {
	if (brandsState.value === null) {
		load(true)
		return []
	}
	// return clientMultiBranded

	if (!clientMultiBranded || (onlyShowDataSubjectsAssociatedWithSelectedBrand.value && brandOptions.value.length === 1 && !userBrandIdsFilter.value.includes(0))) {
		changeSelectedBrand(brandsState.value[0].value)
		return brandsState.value
	} else {
		return brandsState.value
	}
})

export const showCsPortalBrandSelector = computed(() => {
	return csPortalBrandSelector.value.length >= 2 && onlyShowDataSubjectsAssociatedWithSelectedBrand.value
})

export const resetAdminPortalBrandFilter = () => {
	changeAdminPortalBrandFilter(null)
}
