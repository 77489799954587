/* eslint-disable max-lines */
import {
	CAN_CREATE_UPDATE_CONSENT_BANNERS,
	CAN_SEE_CONSENT_BANNERS,
	CAN_SEE_POLICY_TABLES,
	SEE_CS_PORTAL_AREA,
	SEE_SENDER_EMAILS_MODULE,
	SEE_AUDIT_AND_LOG_MODULE,
	CAN_CREATE_AND_EDIT_CS_PORTAL_USERS,
	CAN_CREATE_AND_EDIT_CS_PORTAL_PRODUCT_ROLES,
	CAN_SEE_CS_PORTAL_PRODUCT_ROLES_MODULE,
	CAN_SEE_MANAGE_CS_PORTAL_USERS_MODULE,
	CAN_SEE_THEMES,
	SEE_LINKS_AREA,
	CAN_SEE_EXPORTS_MODULE,
	SITE_SCANNER_FULL_ACCESS,
	SITE_SCANNER_READ_ONLY,
	CAN_CREATE_SUPPORT_TICKETS,
	CAN_VIEW_SUPPORT_TICKETS,
	CAN_SEE_ADMIN_PORTAL_PRODUCT_ROLES_MODULE,
	CAN_CREATE_AND_EDIT_ADMIN_PORTAL_PRODUCT_ROLES,
	CAN_SEE_COOKIE_BANNERS,
	CAN_SEE_COOKIE_CATEGORIES,
	CAN_SEE_COOKIE_SCRIPTS,
	CAN_SEE_COOKIE_TEXT_TEMPLATES,
	CAN_SEE_COOKIE_APPEARANCE_TEMPLATES,
	CAN_SEE_COOKIE_COLLECTION_TEMPLATES,
	CAN_SEE_COOKIE_VENDORS,
	CAN_SEE_META_DATA_KEYS_MODULE,
	CAN_SEE_CHANNELS_MODULE,
	CAN_SEE_STATEMENTS_PAGE,
	CAN_SEE_CONFIRMATION_PAGES_MODULE,
	CAN_SEE_HEADERS_MODULE,
	CAN_SEE_SYSTEM_EMAILS_MODULE,
	CAN_SEE_FOOTERS_MODULE,
	CAN_SEE_ROPA_DASHBOARD,
	CAN_SEE_ROPA_PROCESSES,
	CAN_SEE_ROPA_SECURITY_MATRIX,
	CAN_SEE_ROPA_CONFIGURATION,
	CAN_SEE_CHANNEL_DEPENDENCIES,
	CAN_SEE_PROGRESSIVE_PROFILING,
	CAN_SEE_IAB_TEXT_TRANSLATIONS,
	CAN_SEE_CONSENT_COLLECTIONS,
	CAN_SEE_TRANSACTION_TYPES,
	SEE_PREF_WIDGETS_AREA,
	CAN_SEE_SSO_CONFIGURATION
} from '../../../shared/permissions/admin-portal-permissions.js'
import {
	MANAGE_PREFERENCE_LINK,
	CS_PORTAL_CONFIG,
	PUBLIC_PORTAL_LINKS,
	ACTION_LINKS,
	PREFERENCE_PAGE_LINKS,
	MANAGE_PUBLIC_PORTAL_LINK,
	MANAGE_LINK_TRANSLATION,
	CHANNELS,
	MANAGE_CHANNEL,
	HEADERS,
	FOOTERS,
	CONNECTOR_SERVICE,
	MANAGE_INTEGRATION_SERVICE,
	MANAGE_SYSTEM_INTEGRATION,
	PROCESSING_DASHBOARD,
	PROCESSING_PROCESSES,
	PROCESSING_LOOK_UP_DATA,
	PROCESSING_SECURITY_MATRIX,
	PROCESSING_MANAGE_PROCESS,
	CONFIRMATION_PAGES,
	MANAGE_CONFIRMATION_PAGE,
	SYSTEM_EMAILS,
	MANAGE_SYSTEM_EMAIL,
	MANAGE_SYSTEM_EMAIL_TRANSLATION,
	PREVIEW,
	EXTENDED_PREFERENCES,
	MANAGE_EXTENDED_PREFERENCE_TRANSLATION,
	WHITELISTING,
	ACCESS_KEYS,
	MANAGE_ACCESS_KEY,
	ADMIN_PORTAL_USERS,
	ADMIN_PORTAL_MANAGE_USER,
	ADMIN_PORTAL_API_USERS,
	ADMIN_PORTAL_API_MANAGE_USER,
	SENDER_EMAIL_ADDRESSES,
	BRANDS,
	LOG_VIEWER,
	LANGUAGES,
	DATA_SUBJECT_RULES,
	DATA_SUBJECT_GROUPING_RULES,
	MANAGE_CHANNEL_TRANSLATION,
	PREFERENCE_WIDGETS,
	MANAGE_PREFERENCE_WIDGET,
	META_DATA_KEYS,
	PRIVACY_POLICIES,
	MANAGE_PRIVACY_POLICY,
	MANAGE_STATEMENT_GROUP,
	STATEMENT_GROUPS,
	DASHBOARD,
	DATA_LAKES_EXPORT,
	STATEMENTS,
	MANAGE_STATEMENT,
	MANAGE_STATEMENT_TRANSLATION,
	RESET_PASSWORD,
	FORGOTTEN_PASSWORD,
	SESSION_EXPIRED,
	THEMES,
	SSO_ERROR,
	SSO_LOGOUT,
	SITE_SCANNER,
	SITE_SCANNER_REQUEST_SCAN,
	SITE_SCANNER_REPORT,
	SITE_SCANNER_DOWNLOAD_COOKIE,
	SITE_SCANNER_DOWNLOAD_ADVANCED_TRACKERS,
	SITE_SCANNER_DOWNLOAD_SUB_RESOURCES,
	PROGRESSIVE_PROFILING,
	MANAGE_PROGRESSIVE_PROFILE,
	SUPPORT_CREATE_TICKET,
	SUPPORT_VIEW_TICKET,
	DIRECT_LOGIN_NOT_ALLOWED,
	COOKIE_CATEGORIES,
	COOKIE_SCRIPTS,
	COOKIE_COLLECTION_TEMPLATES,
	MANAGE_COOKIE_COLLECTION_TEMPLATE,
	COOKIE_TEXT_TEMPLATES,
	MANAGE_COOKIE_TEXT_TEMPLATE,
	MANAGE_COOKIE_TEXT_TEMPLATE_TRANSLATION,
	COOKIE_APPEARANCE_TEMPLATES,
	MANAGE_COOKIE_APPEARANCE_TEMPLATE,
	COOKIE_BANNERS,
	MANAGE_COOKIE_BANNER,
	CHANNEL_DEPENDENCIES,
	ACCOUNT_LOCKED_OUT,
	COOKIE_VENDORS,
	CONSENT_STRUCTURE,
	IAB_TEXT_MANAGEMENT,
	CONSENT_COLLECTION,
	CONSENT_COLLECTION_WIDGETS,
	MANAGE_CONSENT_COLLECTION_WIDGET,
	CONSENT_COLLECTION_PREFERENCE_PAGES,
	MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE,
	TRANSACTION_TYPES,
	MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION,
	MANAGE_CONSENT_COLLECTION_WIDGET_TRANSLATION,
	SSO_CONFIGURATION,
	MANAGE_SSO_CONFIGURATION
} from './route-names.js'
import {
	USER_MANAGEMENT,
	USER_MANAGEMENT_CREATE_USER,
	USER_MANAGEMENT_EDIT_USER,
	CS_PORTAL_PRODUCT_ROLES,
	CS_PORTAL_PRODUCT_ROLES_CREATE_ROLE,
	CS_PORTAL_PRODUCT_ROLES_EDIT_ROLE,
	ADMIN_PORTAL_PRODUCT_ROLES,
	ADMIN_PORTAL_PRODUCT_ROLES_CREATE_ROLE,
	ADMIN_PORTAL_PRODUCT_ROLES_EDIT_ROLE
}
from '../../../shared/router/route-names.js'

export default [
	{ path: '/', redirect: { name: 'dashboard' } },
	{
		path: '/meta-data-keys',
		name: META_DATA_KEYS,
		component: () => import('../components/views/meta-data-keys/_meta-data-keys.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_META_DATA_KEYS_MODULE
		},
		props: true
	},
	{
		path: '/privacy-policies',
		name: PRIVACY_POLICIES,
		component: () => import('../components/views/privacy-policies/_privacy-policies.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/manage-privacy-policy',
		name: MANAGE_PRIVACY_POLICY,
		component: () => import('../components/views/privacy-policies/_manage-privacy-policy.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/manage-statement-group',
		name: MANAGE_STATEMENT_GROUP,
		component: () => import('../components/views/statement-groups/_manage-statement-group.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/statement-groups',
		name: STATEMENT_GROUPS,
		component: () => import('../components/views/statement-groups/_statement-groups.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/manage-preference-widget',
		name: MANAGE_PREFERENCE_WIDGET,
		component: () => import('../components/views/preference-widgets/_manage-preference-widget.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/preference-widgets',
		name: PREFERENCE_WIDGETS,
		component: () => import('../components/views/preference-widgets/_preference-widgets.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/progressive-profiling',
		name: PROGRESSIVE_PROFILING,
		component: () => import('../components/views/preference-widgets/progressive-profiling/_progressive-profiling.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_PROGRESSIVE_PROFILING
		}
	},
	{
		path: '/manage-progressive-profile',
		name: MANAGE_PROGRESSIVE_PROFILE,
		component: () => import('../components/views/preference-widgets/progressive-profiling/_manage-progressive-profile.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_PROGRESSIVE_PROFILING
		},
		props: true
	},
	{
		path: '/manage-channel-translation',
		name: MANAGE_CHANNEL_TRANSLATION,
		component: () => import('../components/views/channels/_manage-channel-translation.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_CHANNELS_MODULE
		},
		props: true
	},
	{
		path: '/channel-dependencies',
		name: CHANNEL_DEPENDENCIES,
		component: () => import('../components/views/administration/channel-dependencies/_channel-dependencies.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_CHANNEL_DEPENDENCIES
		}
	},
	{
		path: '/data-subject-rules',
		name: DATA_SUBJECT_RULES,
		component: () => import('../components/views/administration/data-subject-rules/_data-subject-rules.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/data-subject-grouping-rules',
		name: DATA_SUBJECT_GROUPING_RULES,
		component: () => import('../components/views/administration/data-subject-grouping-rules/_data-subject-grouping-rules.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/languages',
		name: LANGUAGES,
		component: () => import('../components/views/administration/languages/_languages.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/log-viewer',
		name: LOG_VIEWER,
		component: () => import('../components/views/administration/_log-viewer.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_AUDIT_AND_LOG_MODULE
		}
	},
	{
		path: '/brands',
		name: BRANDS,
		component: () => import('../components/views/administration/_brands.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/sender-email-addresses',
		name: SENDER_EMAIL_ADDRESSES,
		component: () => import('../components/views/administration/sender-email-addresses/_sender-email-addresses.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_SENDER_EMAILS_MODULE
		}
	},
	{
		path: '/admin-portal-api-users',
		name: ADMIN_PORTAL_API_USERS,
		component: () => import('../components/views/administration/admin-portal-apis/admin-portal-api-users/_admin-portal-api-users.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/admin-portal-api-manage-user',
		name: ADMIN_PORTAL_API_MANAGE_USER,
		component: () => import('../components/views/administration/admin-portal-apis/admin-portal-api-users/_admin-portal-api-manage-user.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/whitelisting',
		name: WHITELISTING,
		component: () => import('../components/views/administration/_whitelisting.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/access-keys',
		name: ACCESS_KEYS,
		component: () => import('../components/views/administration/access-keys/_access-keys.vue'),
		meta: {
			authRequired: true
		},
		props: route => ({
			accessedVia: route.params.accessedVia
		})
	},
	{
		path: '/manage-access-key',
		name: MANAGE_ACCESS_KEY,
		component: () => import('../components/views/administration/access-keys/_manage-access-key.vue'),
		meta: {
			authRequired: true
		},
		props: route => ({
			accessKeyToEdit: route.params.accessKeyToEdit,
			accessKeyType: Number(route.query.accessKeyType)
		})
	},
	{
		path: '/admin-portal-users',
		name: ADMIN_PORTAL_USERS,
		component: () => import('../components/views/administration/admin-portal-users/_admin-portal-users.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/admin-portal-users/manage-user',
		name: ADMIN_PORTAL_MANAGE_USER,
		component: () => import('../components/views/administration/admin-portal-users/_admin-portal-manage-user.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/extended-preferences',
		name: EXTENDED_PREFERENCES,
		component: () => import('../components/views/extended-preferences/_extended-preferences.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/manage-extended-preference-translation',
		name: MANAGE_EXTENDED_PREFERENCE_TRANSLATION,
		component: () => import('../components/views/extended-preferences/_manage-extended-preference-translation.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/preview',
		name: PREVIEW,
		component: () => import('../components/views/preview/_preview.vue')
	},
	{
		path: '/system-emails',
		name: SYSTEM_EMAILS,
		component: () => import('../components/views/system-emails/_system-emails.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_SYSTEM_EMAILS_MODULE
		},
		props: true
	},
	{
		path: '/manage-system-email',
		name: MANAGE_SYSTEM_EMAIL,
		component: () => import('../components/views/system-emails/_manage-system-email.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/manage-system-email-translation',
		name: MANAGE_SYSTEM_EMAIL_TRANSLATION,
		component: () => import('../components/views/system-emails/_manage-system-email-translation.vue'),
		meta: {
			authRequired: true
		},
		props: route => ({
			systemEmailToTranslate: route.params.systemEmailToTranslate
		})
	},
	{
		path: '/themes',
		name: THEMES,
		component: () => import('../components/views/themes/_themes.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_THEMES
		},
		props: true
	},
	{
		path: '/confirmation-pages',
		name: CONFIRMATION_PAGES,
		component: () => import('../components/views/confirmation-pages/_confirmation-pages.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_CONFIRMATION_PAGES_MODULE
		},
		props: true
	},
	{
		path: '/manage-confirmation-page',
		name: MANAGE_CONFIRMATION_PAGE,
		component: () => import('../components/views/confirmation-pages/_manage-confirmation-page.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/manage-process',
		name: PROCESSING_MANAGE_PROCESS,
		component: () => import('../components/views/processing/manage-process/_manage-process.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_ROPA_PROCESSES
		},
		props: true
	},
	{
		path: '/processing-dashboard',
		name: PROCESSING_DASHBOARD,
		component: () => import('../components/views/processing/_dashboard.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_ROPA_DASHBOARD
		},
		props: true
	},
	{
		path: '/processing-processes',
		name: PROCESSING_PROCESSES,
		component: () => import('../components/views/processing/_processes.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_ROPA_PROCESSES
		},
		props: true
	},
	{
		path: '/processing-security-matrix',
		name: PROCESSING_SECURITY_MATRIX,
		component: () => import('../components/views/processing/security-matrix/_security-matrix.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_ROPA_SECURITY_MATRIX
		},
		props: true
	},
	{
		path: '/processing-look-up-data',
		name: PROCESSING_LOOK_UP_DATA,
		component: () => import('../components/views/processing/look-up-data/_look-up-data.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_ROPA_CONFIGURATION
		},
		props: true
	},
	{
		path: '/footers',
		name: FOOTERS,
		component: () => import('../components/views/headers-and-footers/footers/_footers.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_FOOTERS_MODULE
		},
		props: true
	},
	{
		path: '/headers',
		name: HEADERS,
		component: () => import('../components/views/headers-and-footers/headers/_headers.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_HEADERS_MODULE
		},
		props: true
	},
	{
		path: '/connector-service',
		name: CONNECTOR_SERVICE,
		component: () => import('../components/views/connector-service/_connector-service.vue'),
		meta: {
			authRequired: true
		},
		props: route => ({
			accessedVia: route.params.accessedVia
		})
	},
	{
		path: '/manage-integration-service',
		name: MANAGE_INTEGRATION_SERVICE,
		component: () => import('../components/views/connector-service/manage-integration-service/_manage-integration-service.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/manage-system-integration',
		name: MANAGE_SYSTEM_INTEGRATION,
		component: () => import('../components/views/connector-service/manage-system-integration/_manage-system-integration.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/manage-channel',
		name: MANAGE_CHANNEL,
		component: () => import('../components/views/channels/_manage-channel.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_CHANNELS_MODULE
		},
		props: true
	},
	{
		path: '/channels',
		name: CHANNELS,
		component: () => import('../components/views/channels/_channels.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_CHANNELS_MODULE
		},
		props: route => ({
			previouslySelectedLanguage: route.params.previouslySelectedLanguage
		})
	},
	{
		path: '/consent-structure',
		name: CONSENT_STRUCTURE,
		component: () => import('../components/views/consent-structure/_consent-structure.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/statements',
		name: STATEMENTS,
		component: () => import('../components/views/statements/_statements.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_STATEMENTS_PAGE
		},
		props: route => ({
			accessedVia: route.params.accessedVia,
			previouslySelectedLanguage: route.params.previouslySelectedLanguage
		})
	},
	{
		path: '/manage-statement',
		name: MANAGE_STATEMENT,
		component: () => import('../components/views/statements/manage-statement/_manage-statement.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_STATEMENTS_PAGE
		},
		props: route => ({
			statementToEdit: route.params.statementToEdit,
			channelName: route.params.channelName,
			accessedVia: route.params.accessedVia
		})
	},
	{
		path: '/manage-statement-translation',
		name: MANAGE_STATEMENT_TRANSLATION,
		component: () => import('../components/views/statements/manage-statement/_manage-statement-translation.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_STATEMENTS_PAGE
		},
		props: route => ({
			statementTranslationToEdit: route.params.statementTranslationToEdit,
			channelName: route.params.channelName,
			languageName: route.params.languageName,
			accessedVia: route.params.accessedVia
		})
	},
	{
		path: '/manage-public-portal-link',
		name: MANAGE_PUBLIC_PORTAL_LINK,
		component: () => import('../components/views/links/public-portal-links/_manage-public-portal-link.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_LINKS_AREA
		},
		props: route => ({
			linkToEdit: route.params.linkToEdit,
			linkType: Number(route.query.linkType)
		})
	},
	{
		path: '/manage-preference-link',
		name: MANAGE_PREFERENCE_LINK,
		component: () => import('../components/views/links/preference-links/_manage-preference-link.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_LINKS_AREA
		},
		props: route => ({
			linkToEdit: route.params.linkToEdit,
			linkType: Number(route.query.linkType),
			assignedLanguageItems: route.params.assignedLanguageItems,
			cloneMode: route.params.cloneMode,
			accessedViaHub: route.params.accessedViaHub
		})
	},
	{
		path: '/links/preference-page-links',
		name: PREFERENCE_PAGE_LINKS,
		component: () => import('../components/views/links/preference-links/_preference-links.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_LINKS_AREA
		},
		props: route => ({
			accessedViaTranslations: route.params.accessedViaTranslations
		})
	},
	{
		path: '/links/action-links',
		name: ACTION_LINKS,
		component: () => import('../components/views/links/action-links/_action-links.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_LINKS_AREA
		},
		props: route => ({
			accessedViaTranslations: route.params.accessedViaTranslations
		})
	},
	{
		path: '/public-portal-links',
		name: PUBLIC_PORTAL_LINKS,
		component: () => import('../components/views/links/public-portal-links/_public-portal-links.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_LINKS_AREA
		},
		props: route => ({
			accessedViaTranslations: route.params.accessedViaTranslations
		})
	},
	{
		path: '/manage-link-translation',
		name: MANAGE_LINK_TRANSLATION,
		component: () => import('../components/views/links/preference-links/_manage-link-translation.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_LINKS_AREA
		},
		props: route => ({
			userFullPermissions: route.params.userFullPermissions,
			language: route.params.language,
			linkTranslationToEdit: route.params.linkTranslationToEdit,
			linkAuthType: route.params.linkAuthType,
			linkType: Number(route.query.linkType)
		})
	},
	{
		path: '/cs-portal-config',
		name: CS_PORTAL_CONFIG,
		component: () => import('../components/views/cs-portal-config/_cs-portal-config.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_CS_PORTAL_AREA
		}
	},
	{
		path: '/dashboard',
		name: DASHBOARD,
		component: () => import('../components/views/cookie-module/_dashboard.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/data-exports',
		name: DATA_LAKES_EXPORT,
		component: () => import('../components/views/exports/_data-exports.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_EXPORTS_MODULE
		}
	},
	{
		path: '/consent-banners',
		name: 'consent-banners',
		component: () => import('../components/views/cookie-module/consent-banners/_consent-banners.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_CONSENT_BANNERS
		}
	},
	{
		path: '/cookie-banners/create-banner',
		name: 'create-cookie-banner',
		component: () => import('../components/views/cookie-module/consent-banners/configure-cookie-banner/_configure-cookie-banner.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_CREATE_UPDATE_CONSENT_BANNERS
		}
	},
	{
		path: '/cookie-banners/:cookieBannerId',
		name: 'configure-cookie-banner',
		component: () => import('../components/views/cookie-module/consent-banners/configure-cookie-banner/_configure-cookie-banner.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_CONSENT_BANNERS
		}
	},
	// cookies v2
	{
		path: '/cookie-categories',
		name: COOKIE_CATEGORIES,
		component: () => import('../components/views/cookie-module/cookies/cookie-categories/_cookie-categories.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_COOKIE_CATEGORIES
		}
	},
	{
		path: '/cookie-scripts',
		name: COOKIE_SCRIPTS,
		component: () => import('../components/views/cookie-module/cookies/cookie-scripts/_cookie-scripts.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_COOKIE_SCRIPTS
		}
	},
	{
		path: '/cookie-banners',
		name: COOKIE_BANNERS,
		component: () => import('../components/views/cookie-module/cookies/cookie-banners/_cookie-banners.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_COOKIE_BANNERS
		}
	},
	{
		path: '/manage-cookie-banner',
		name: MANAGE_COOKIE_BANNER,
		component: () => import('../components/views/cookie-module/cookies/cookie-banners/_manage-cookie-banner.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_COOKIE_BANNERS
		},
		props: true
	},
	{
		path: '/cookie-collection-templates',
		name: COOKIE_COLLECTION_TEMPLATES,
		component: () => import('../components/views/cookie-module/cookies/cookie-collection-templates/_cookie-collection-templates.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_COOKIE_COLLECTION_TEMPLATES
		}
	},
	{
		path: '/manage-cookie-collection-template',
		name: MANAGE_COOKIE_COLLECTION_TEMPLATE,
		component: () => import('../components/views/cookie-module/cookies/cookie-collection-templates/_manage-cookie-collection-template.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_COOKIE_COLLECTION_TEMPLATES
		},
		props: true
	},
	{
		path: '/cookie-text-templates',
		name: COOKIE_TEXT_TEMPLATES,
		component: () => import('../components/views/cookie-module/cookies/cookie-text-templates/_cookie-text-templates.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_COOKIE_TEXT_TEMPLATES
		},
		props: route => ({
			previouslySelectedLanguage: route.params.previouslySelectedLanguage,
			accessedVia: route.params.accessedVia
		})
	},
	{
		path: '/manage-cookie-text-template',
		name: MANAGE_COOKIE_TEXT_TEMPLATE,
		component: () => import('../components/views/cookie-module/cookies/cookie-text-templates/_manage-cookie-text-template.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_COOKIE_TEXT_TEMPLATES
		},
		props: true
	},
	{
		path: '/manage-cookie-text-template-translation',
		name: MANAGE_COOKIE_TEXT_TEMPLATE_TRANSLATION,
		component: () => import('../components/views/cookie-module/cookies/cookie-text-templates/_manage-cookie-text-template-translation.vue'),
		meta: {
			authRequired: true
			// ADD PERMISSIONS
			// permissionRequired: CAN_SEE_POLICY_TABLES
		},
		props: true
	},
	{
		path: '/cookie-appearance-templates',
		name: COOKIE_APPEARANCE_TEMPLATES,
		component: () => import('../components/views/cookie-module/cookies/cookie-appearances-templates/_cookie-appearance-templates.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_COOKIE_APPEARANCE_TEMPLATES
		}
	},
	{
		path: '/manage-cookie-appearance-template',
		name: MANAGE_COOKIE_APPEARANCE_TEMPLATE,
		component: () => import('../components/views/cookie-module/cookies/cookie-appearances-templates/_manage-cookie-appearance-template.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_COOKIE_APPEARANCE_TEMPLATES
		},
		props: true
	},
	{
		path: '/vendors',
		name: COOKIE_VENDORS,
		component: () => import('../components/views/cookie-module/cookies/vendors/_vendors.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_COOKIE_VENDORS
		}
	},
	{
		path: '/iab-text-management',
		name: IAB_TEXT_MANAGEMENT,
		component: () => import('../components/views/cookie-module/cookies/iab-text-management/_iab-text-management.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_IAB_TEXT_TRANSLATIONS
		}
	},
	// Policy Tables
	{
		path: '/policy-tables',
		name: 'policy-tables',
		component: () => import('../components/views/cookie-module/policy-tables/_policy-tables.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_POLICY_TABLES
		}
	},
	{
		path: '/cookie-policy-table/create-cookie-policy-table',
		name: 'create-cookie-policy-table',
		component: () => import('../components/views/cookie-module/policy-tables/policy-tables-configure/_policy-tables-configure.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_POLICY_TABLES
		}
	},
	{
		path: '/cookie-policy-table/:policyTableId',
		name: 'configure-cookie-policy-table',
		component: () => import('../components/views/cookie-module/policy-tables/policy-tables-configure/_policy-tables-configure.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_POLICY_TABLES
		}
	},
	// Site Scanner
	{
		path: '/cookie-scanner',
		name: SITE_SCANNER,
		component: () => import('../components/views/cookie-module/site-scanner/_site-scanner.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SITE_SCANNER_READ_ONLY
		}
	},
	{
		path: '/cookie-scanner-request-scan',
		name: SITE_SCANNER_REQUEST_SCAN,
		component: () => import('../components/views/cookie-module/site-scanner/_site-scanner-request-scan.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SITE_SCANNER_FULL_ACCESS
		},
		props: route => ({
			scanTypes: route.params.scanTypes,
			maxUrls: route.params.maxUrls,
			actionTypes: route.params.actionTypes,
			loginTemplates: route.params.loginTemplates
		})
	},
	{
		path: '/cookie-scanner-report',
		name: SITE_SCANNER_REPORT,
		component: () => import('../components/views/cookie-module/site-scanner/_site-scanner-report.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SITE_SCANNER_READ_ONLY
		},
		props: route => ({
			scanGuid: route.query.scanGuid,
			maxUrls: route.params.maxUrls
		})
	},
	{
		path: '/cookie-scanner/download-cookie',
		name: SITE_SCANNER_DOWNLOAD_COOKIE,
		component: () => import('../components/views/cookie-module/site-scanner/_site-scanner-download-cookie.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SITE_SCANNER_READ_ONLY
		},
		props: route => ({
			scanGuid: route.query.scanGuid,
			cookiename: route.query.cookieName
		})
	},
	{
		path: '/cookie-scanner/download-advancedtrackers',
		name: SITE_SCANNER_DOWNLOAD_ADVANCED_TRACKERS,
		component: () => import('../components/views/cookie-module/site-scanner/_site-scanner-download-advanced-trackers.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SITE_SCANNER_READ_ONLY
		},
		props: route => ({
			scanGuid: route.query.scanGuid
		})
	},
	{
		path: '/cookie-scanner/download-subresources',
		name: SITE_SCANNER_DOWNLOAD_SUB_RESOURCES,
		component: () => import('../components/views/cookie-module/site-scanner/_site-scanner-download-sub-resources.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SITE_SCANNER_READ_ONLY
		},
		props: route => ({
			scanGuid: route.query.scanGuid
		})
	},
	// Consent Collection
	{
		path: '/consent-collection',
		name: CONSENT_COLLECTION,
		component: () => import('../components/views/consent-collection/_consent-collection.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_CONSENT_COLLECTIONS
		},
		props: true
	},
	{
		path: '/consent-collection/consent-collection-widgets',
		name: CONSENT_COLLECTION_WIDGETS,
		component: () => import('../components/views/consent-collection/consent-collection-widgets/_consent-collection-widgets.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_PREF_WIDGETS_AREA
		},
		props: true
	},
	{
		path: '/consent-collection/preference-pages',
		name: CONSENT_COLLECTION_PREFERENCE_PAGES,
		component: () => import('../components/views/consent-collection/preference-pages/_preference-pages.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_LINKS_AREA
		},
		props: true
	},
	{
		path: '/consent-collection/manage-preference-page',
		name: MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE,
		component: () => import('../components/views/consent-collection/preference-pages/_manage-preference-page.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_LINKS_AREA
		},
		props: route => ({
			userFullPermissions: route.params.userFullPermissions,
			id: Number(route.query.id),
			accessedViaHub: route.params.accessedViaHub
		})
	},
	{
		path: '/consent-collection/manage-consent-collection-widget',
		name: MANAGE_CONSENT_COLLECTION_WIDGET,
		component: () => import('../components/views/consent-collection/consent-collection-widgets/_manage-consent-collection-widget.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_PREF_WIDGETS_AREA
		},
		props: route => ({
			id: Number(route.query.id),
			accessedViaHub: route.params.accessedViaHub
		})
	},
	{
		path: '/consent-collection/manage-consent-collection-widget-translation',
		name: MANAGE_CONSENT_COLLECTION_WIDGET_TRANSLATION,
		component: () => import('../components/views/consent-collection/consent-collection-widgets/_manage-consent-collection-widget-translation.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_PREF_WIDGETS_AREA
		},
		props: route => ({
			userFullPermissions: route.params.userFullPermissions,
			id: String(route.query.id),
			languageId: Number(route.query.languageId)
		})
	},
	{
		path: '/consent-collection/manage-preference-page-translation',
		name: MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION,
		component: () => import('../components/views/consent-collection/preference-pages/manage-translation/_manage-preference-page-translation.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_LINKS_AREA
		},
		props: route => ({
			userFullPermissions: route.params.userFullPermissions,
			id: Number(route.query.id),
			languageId: Number(route.query.languageId)
		})
	},
	{
		path: '/transaction-types',
		name: TRANSACTION_TYPES,
		component: () => import('../components/views/transaction-types/_transaction-types.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_TRANSACTION_TYPES
		},
		props: true
	},

	// Admin Portal CS Portal Module
	{
		path: '/product-roles/create-role',
		name: CS_PORTAL_PRODUCT_ROLES_CREATE_ROLE,
		component: () => import('../../../shared/components/product-roles/_product-roles-create-role.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_CREATE_AND_EDIT_CS_PORTAL_PRODUCT_ROLES
		},
		props: route => ({
			productIdToView: Number(route.query.productIdToView),
			loggedInProductId: Number(route.query.loggedInProductId)
		})
	},
	{
		path: '/product-roles/edit-role',
		name: CS_PORTAL_PRODUCT_ROLES_EDIT_ROLE,
		component: () => import('../../../shared/components/product-roles/_product-roles-edit-role.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_CS_PORTAL_PRODUCT_ROLES_MODULE
		},
		props: route => ({
			roleId: Number(route.query.roleId),
			productIdToView: Number(route.query.productIdToView),
			loggedInProductId: Number(route.query.loggedInProductId)
		})
	},
	{
		path: '/product-roles',
		name: CS_PORTAL_PRODUCT_ROLES,
		component: () => import('../../../shared/components/product-roles/_product-roles.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_CS_PORTAL_PRODUCT_ROLES_MODULE
		},
		props: {
			productIdToView: Number(2),
			loggedInProductId: Number(1)
		}
	},
	{
		path: '/user-management/create-user',
		name: USER_MANAGEMENT_CREATE_USER,
		component: () => import('../../../shared/components/user-management/_user-management-create-user.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_CREATE_AND_EDIT_CS_PORTAL_USERS
		},
		props: () => ({
			productId: 1
		})
	},
	{
		path: '/user-management/edit-user',
		name: USER_MANAGEMENT_EDIT_USER,
		component: () => import('../../../shared/components/user-management/_user-management-edit-user.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_MANAGE_CS_PORTAL_USERS_MODULE
		},
		props: route => ({
			userId: Number(route.query.userId),
			productId: 1
		})
	},
	{
		path: '/user-management',
		name: USER_MANAGEMENT,
		component: () => import('../../../shared/components/user-management/_user-management.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_MANAGE_CS_PORTAL_USERS_MODULE
		},
		props: () => ({
			productID: 1
		})
	},
	{
		path: '/sso-configuration',
		name: SSO_CONFIGURATION,
		component: () => import('../components/views/administration/sso-configuration/_sso-configuration.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_SSO_CONFIGURATION
		}
	},
	{
		path: '/manage-sso-configuration',
		name: MANAGE_SSO_CONFIGURATION,
		component: () => import('../../../admin-portal/src/components/views/administration/sso-configuration/_manage-sso-configuration.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_SSO_CONFIGURATION
		},
		props: true
	},
	{
		path: '/sso/error',
		name: SSO_ERROR,
		component: () => import('../../../admin-portal/src/components/views/_sso-error.vue'),
		// Allows props to be passed to the 403 page through route
		// params, such as `resource` to define what wasn't found.
		props: true
	},
	{
		path: '/sso/logout',
		name: SSO_LOGOUT,
		component: () => import('../../../admin-portal/src/components/views/_sso-logout.vue'),
		// Displays message to user that logout has occurred
		props: true
	},
	{
		path: '/forgotten-password',
		name: FORGOTTEN_PASSWORD,
		component: () => import('../components/views/login/_forgotten-password.vue')
	},
	{
		path: '/session-expired',
		name: SESSION_EXPIRED,
		component: () => import('../components/views/session-expired.vue')
	},
	{
		path: '/reset-password',
		name: RESET_PASSWORD,
		component: () => import('../components/views/login/_reset-password.vue'),
		props: route => ({
			hash: route.query.hash
		})
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../components/views/login/_login.vue'),
		props: route => ({
			previousRoute: route.params.previousRoute
		})
	},
	{
		path: '/otp-login',
		name: 'otp-login',
		component: () => import('../components/views/login/_otp-login.vue'),
		props: route => ({
			otp: route.query.otp
		})
	},
	{
		path: '/404',
		name: '404',
		component: () => import('../components/views/_404.vue'),
		// Allows props to be passed to the 404 page through route
		// params, such as `resource` to define what wasn't found.
		props: true
	},
	{
		path: '/403',
		name: '403',
		component: () => import('../components/views/_403.vue'),
		// Allows props to be passed to the 403 page through route
		// params, such as `resource` to define what wasn't found.
		props: true
	},
	// Redirect any unmatched routes to the 404 page. This may
	// require some server configuration to work in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	{
		path: '*',
		redirect: '404'
	},
	// Support Module
	{
		path: '/support-new',
		name: SUPPORT_CREATE_TICKET,
		component: () => import('../components/views/support/_create-support-ticket.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_CREATE_SUPPORT_TICKETS
		}
	},
	{
		path: '/support-view',
		name: SUPPORT_VIEW_TICKET,
		component: () => import('../components/views/support/_view-support-ticket.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_VIEW_SUPPORT_TICKETS
		}
	},
	// SSO Direct Login
	{
		path: '/direct-login-not-allowed',
		name: DIRECT_LOGIN_NOT_ALLOWED,
		component: () => import('../../../shared/components/direct-login-not-allowed.vue'),
		props: true
	},
	// Admin portal product roles
	{
		path: '/admin-portal-product-roles',
		name: ADMIN_PORTAL_PRODUCT_ROLES,
		component: () => import('../../../shared/components/product-roles/_product-roles.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_ADMIN_PORTAL_PRODUCT_ROLES_MODULE
		},
		props: {
			productIdToView: Number(1),
			loggedInProductId: Number(1)
		}
	},
	{
		path: '/product-roles/create-role',
		name: ADMIN_PORTAL_PRODUCT_ROLES_CREATE_ROLE,
		component: () => import('../../../shared/components/product-roles/_product-roles-create-role.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_CREATE_AND_EDIT_ADMIN_PORTAL_PRODUCT_ROLES
		},
		props: route => ({
			productIdToView: Number(route.query.productIdToView),
			loggedInProductId: Number(route.query.loggedInProductId)
		})
	},
	{
		path: '/product-roles/edit-role',
		name: ADMIN_PORTAL_PRODUCT_ROLES_EDIT_ROLE,
		component: () => import('../../../shared/components/product-roles/_product-roles-edit-role.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CAN_SEE_ADMIN_PORTAL_PRODUCT_ROLES_MODULE
		},
		props: route => ({
			roleId: Number(route.query.roleId),
			productIdToView: Number(route.query.productIdToView),
			loggedInProductId: Number(route.query.loggedInProductId)
		})
	},
	{
		path: '/account-locked-out',
		name: ACCOUNT_LOCKED_OUT,
		component: () => import('../components/views/login/_account-locked-out.vue')
	}
]
