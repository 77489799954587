<template>
	<div
		:class="tooltipText ? 'd-flex flex-row cassie-horizontal-sm' : ''"
	>
		<v-btn
			v-bind="$props"
			color="white--text blue darken-2"
			class="px-10"
			:class="[
				fullWidth ? 'cassie-input-width-xl' : '',
				smallWidth ? 'cassie-input-width-sm' : '',
				getQaSelectorClass()
			]"
			:data-testid="testId"
			v-on="$listeners"
		>
			<slot />
		</v-btn>
		<v-tooltip
			v-if="tooltipText"
			right
		>
			<template #activator="{ on }">
				<v-icon
					class="cassie-pab-tooltip-icon-margin"
					light
					v-on="on"
				>
					mdi-information
				</v-icon>
			</template>
			<span>
				{{ tooltipText }}
			</span>
		</v-tooltip>
	</div>
</template>

<script>
import { VBtn } from 'vuetify/lib'

export default {
	name: 'primary-action-button',
	extends: VBtn,
	props: {
		tooltipText: {
			type: String
		},
		testId: {
			type: String,
			default: 'cassie_primaryactionbutton'
		},
		fullWidth: {
			type: Boolean,
			default: false
		},
		smallWidth: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		getQaSelectorClass () {
			const buttonText = this.$slots.default.find(({ text }) => text).text.trim().replace(/\s/g, '-').toLowerCase()
			return `qa-secondaryactionbutton-${buttonText}`
		}
	}
}
</script>
<style lang="scss">
	.cassie-pab-tooltip-icon-margin {
		margin-left: 2px;
	}
</style>
