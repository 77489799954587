export const SEE_COOKIE_MODULE_AREA = 1
export const COOKIE_MODULE_AREA_FULL_PERMISSIONS = 2
export const CAN_SEE_CONSENT_BANNERS = 3
export const CAN_CREATE_UPDATE_CONSENT_BANNERS = 4
export const CAN_DELETE_CONSENT_BANNERS = 5
export const CAN_SEE_POLICY_TABLES = 6
export const CAN_CREATE_UPDATE_POLICY_TABLES = 7
export const CAN_DELETE_POLICY_TABLES = 8
export const SITE_SCANNER_READ_ONLY = 9
export const SITE_SCANNER_FULL_ACCESS = 102
export const SEE_CS_PORTAL_AREA = 19
export const SEE_LINKS_AREA = 20
export const LINKS_AREA_FULL_PERMISSIONS = 21
export const SEE_PREF_WIDGETS_AREA = 22
export const PREF_WIDGETS_AREA_FULL_PERMISSIONS = 23
export const SEE_AUDIT_HISTORY = 24
// export const SEE_DASHBOARD_MODULE = 25 NOW ARCHIVED
// export const DASHBOARD_MODULE_FULL_PERMISSIONS = 26 NOW ARCHIVED
export const SEE_ADMINISTRATION_MODULE = 27
export const ADMINISTRATION_MODULE_FULL_PERMISSIONS = 28
export const SEE_USERS_MODULE = 29
export const USERS_MODULE_FULL_PERMISSIONS = 30
export const SEE_ADMIN_PORTAL_USERS_MODULE = 31
export const ADMIN_PORTAL_USERS_MODULE_FULL_PERMISSIONS = 32
export const SEE_SYSTEM_USERS_MODULE = 33
export const SYSTEM_USERS_MODULE_FULL_PERMISSIONS = 34
export const SEE_AUDIT_AND_LOG_MODULE = 35
export const AUDIT_AND_LOG_MODULE_FULL_PERMISSIONS = 36
export const PRIVACY_POLICIES_MODULE = 37
export const PRIVACY_POLICIES_MODULE_FULL_PERMISSIONS = 38
export const SEE_ACCESS_KEYS_MODULE = 39
export const ACCESS_KEYS_MODULE_FULL_PERMISSIONS = 40
export const SEE_SENDER_EMAILS_MODULE = 41
export const SENDER_EMAILS_MODULE_FULL_PERMISSIONS = 42
export const SEE_LANGUAGES_MODULE = 43
export const LANGUAGES_MODULE_FULL_PERMISSIONS = 44
export const SEE_BRANDS_MODULE = 45
export const BRANDS_MODULE_FULL_PERMISSIONS = 46
export const SEE_BUSINESS_RULES_MODULE = 47
export const BUSINESS_RULES_MODULE_FULL_PERMISSIONS = 48
export const CAN_SEE_WHITELIST_MODULE = 49
export const CAN_CREATE_AND_UPDATE_WHITELIST = 50
export const CAN_SEE_HEADERS_MODULE = 52
export const CAN_CREATE_AND_UPDATE_HEADERS = 53
export const CAN_SEE_FOOTERS_MODULE = 54
export const CAN_CREATE_UPDATE_FOOTERS = 55
// Statement Permissions
export const CAN_SEE_COMPONENTS_MODULE = 60
export const CAN_CREATE_DRAFT_STATEMENTS = 61
export const CAN_APPROVE_DRAFT_STATEMENTS = 62
export const CAN_PUBLISH_DRAFT_STATEMENTS = 63
export const CAN_DELETE_STATEMENTS = 64
export const CAN_SEE_SYSTEM_EMAILS_MODULE = 65
export const SYSTEM_EMAILS_MODULE_FULL_PERMISSIONS = 66
export const HEADERS_FOOTERS_MODULE_FULL_PERMISSIONS = 68
export const COMPONENTS_MODULE_FULL_PERMISSIONS = 70
// Verify domain and email addresses
// Removed ADO #13229 export const SEE_VERIFY_DOMAIN_OR_EMAIL_MODULE = 71
export const VERIFY_DOMAIN_OR_EMAIL_FULL_PERMISSIONS = 72
// CS Portal Config
export const CAN_SEE_MANAGE_CS_PORTAL_USERS_MODULE = 73
export const CAN_CREATE_AND_EDIT_CS_PORTAL_USERS = 74
export const CAN_DELETE_CS_PORTAL_USERS = 75
export const CAN_SEE_CS_PORTAL_PRODUCT_ROLES_MODULE = 76
export const CAN_CREATE_AND_EDIT_CS_PORTAL_PRODUCT_ROLES = 77
export const CAN_DELETE_CS_PORTAL_PRODUCT_ROLES = 78
// Additional components permissions
export const CAN_SEE_CONFIRMATION_PAGES_MODULE = 79
export const CONFIRMATION_PAGES_MODULE_FULL_PERMISSIONS = 80
export const CAN_SEE_EXTENDED_PREFERENCES_MODULE = 81
export const EXTENDED_PREFERENCES_MODULE_FULL_PERMISSIONS = 82
export const CAN_SEE_META_DATA_KEYS_MODULE = 83
export const META_DATA_KEYS_MODULE_FULL_PERMISSIONS = 84
export const CAN_SEE_STATEMENT_GROUPS_MODULE = 85
export const STATEMENT_GROUPS_MODULE_FULL_PERMISSIONS = 86
// Connector service
export const CAN_SEE_CONNECTOR_SERVICE_MODULE = 87
export const CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS = 88
// CS Portal area
export const SEE_CS_PORTAL_CONFIG_MODULE = 89
export const SEE_CS_ADMINISTRATION_MODULE = 90
export const CS_ADMINISTRATION_MODULE_FULL_ACCESS = 91
export const CS_PRODUCT_ROLES_MODULE_FULL_PERMISSIONS = 93
// Data subject rules
export const CAN_SEE_MATCHING_RULES_MODULE = 94
export const MATCHING_RULES_FULL_PERMISSIONS = 95
// Exports
export const CAN_SEE_EXPORTS_MODULE = 98
export const EXPORTS_FULL_PERMISSIONS = 99
export const CAN_DOWNLOAD_LATEST_EXPORT = 110
// Themes
export const CAN_SEE_THEMES = 103
export const CAN_CREATE_UPDATE_THEMES = 104
export const CAN_DELETE_THEMES = 105
// Support
export const CAN_SEE_SUPPORT_MODULE = 106
export const CAN_CREATE_SUPPORT_TICKETS = 107
export const CAN_VIEW_SUPPORT_TICKETS = 108
// Business rules
export const CAN_SEE_GROUPING_RULES_MODULE = 111
export const GROUPING_RULES_FULL_PERMISSIONS = 112
// Channels
export const CAN_SEE_CHANNELS_MODULE = 115
export const CHANNELS_FULL_PERMISSIONS = 116
// Statement Groups
export const CAN_SEE_STATEMENT_GROUPS_PAGE = 123
export const STATEMENT_GROUPS_PAGE_FULL_PERMISSIONS = 124
// Links
export const CAN_SEE_LINKS_PAGE = 125
export const LINKS_PAGE_FULL_PERMISSIONS = 126
export const CAN_SEE_PP_LINKS_PAGE = 127
export const PP_LINKS_PAGE_FULL_PERMISSIONS = 128
// Admin portal product roles
export const CAN_SEE_ADMIN_PORTAL_PRODUCT_ROLES_MODULE = 130
export const CAN_CREATE_AND_EDIT_ADMIN_PORTAL_PRODUCT_ROLES = 131
export const CAN_DELETE_ADMIN_PORTAL_PRODUCT_ROLES = 132
// New cookie module permissions
// cookie banners
export const CAN_SEE_COOKIE_BANNERS = 133
export const COOKIE_BANNERS_FULL_PERMISSIONS = 134
export const CAN_CREATE_UPDATE_COOKIE_BANNERS = 135
export const CAN_DELETE_COOKIE_BANNERS = 136
// cookie categories
export const CAN_SEE_COOKIE_CATEGORIES = 137
export const COOKIE_CATEGORIES_FULL_PERMISSIONS = 138
export const CAN_CREATE_UPDATE_COOKIE_CATEGORIES = 139
export const CAN_DELETE_COOKIE_CATEGORIES = 140
// cookie scripts
export const CAN_SEE_COOKIE_SCRIPTS = 141
export const COOKIE_SCRIPTS_FULL_PERMISSIONS = 142
export const CAN_CREATE_UPDATE_COOKIE_SCRIPTS = 143
export const CAN_DELETE_COOKIE_SCRIPTS = 144
// cookie text templates
export const CAN_SEE_COOKIE_TEXT_TEMPLATES = 145
export const COOKIE_TEXT_TEMPLATES_FULL_PERMISSIONS = 146
export const CAN_CREATE_UPDATE_COOKIE_TEXT_TEMPLATES = 147
export const CAN_DELETE_COOKIE_TEXT_TEMPLATES = 148
// cookie appearance templates
export const CAN_SEE_COOKIE_APPEARANCE_TEMPLATES = 149
export const COOKIE_APPEARANCE_TEMPLATES_FULL_PERMISSIONS = 150
export const CAN_CREATE_UPDATE_COOKIE_APPEARANCE_TEMPLATES = 151
export const CAN_DELETE_COOKIE_APPEARANCE_TEMPLATES = 152
// cookie collection templates
export const CAN_SEE_COOKIE_COLLECTION_TEMPLATES = 153
export const COOKIE_COLLECTION_TEMPLATES_FULL_PERMISSIONS = 154
export const CAN_CREATE_UPDATE_COOKIE_COLLECTION_TEMPLATES = 155
export const CAN_DELETE_COOKIE_COLLECTION_TEMPLATES = 156
// cookie vendors
export const CAN_SEE_IAB_MODULE = 187
export const CAN_SEE_COOKIE_VENDORS = 175
export const COOKIE_VENDORS_FULL_PERMISSIONS = 176
export const CAN_SEE_IAB_TEXT_TRANSLATIONS = 188
export const IAB_TEXT_TRANSLATIONS_FULL_PERMISSIONS = 189
// Statements
export const CAN_SEE_STATEMENTS_PAGE = 160
export const STATEMENTS_FULL_PERMISSIONS = 161
// Ropa
export const ROPA_FULL_PERMISSIONS = 162
export const CAN_SEE_ROPA_MODULE = 166
export const CAN_SEE_ROPA_DASHBOARD = 167
export const CAN_SEE_ROPA_PROCESSES = 168
export const CAN_SEE_ROPA_SECURITY_MATRIX = 169
export const CAN_SEE_ROPA_CONFIGURATION = 170
export const ROPA_DASHBOARD_FULL_PERMISSIONS = 171
export const ROPA_PROCESSES_FULL_PERMISSIONS = 172
export const ROPA_SECURITY_MATRIX_FULL_PERMISSIONS = 173
export const ROPA_CONFIGURATION_FULL_PERMISSIONS = 174

// Channel Dependencies
export const CAN_SEE_CHANNEL_DEPENDENCIES = 163
export const CHANNEL_DEPENDENCIES_FULL_PERMISSIONS = 164
export const CAN_SEE_PROGRESSIVE_PROFILING = 178
export const PROGRESSIVE_PROFILING_FULL_PERMISSIONS = 179
export const CAN_CREATE_UPDATE_PROGRESSIVE_PROFILING = 180
export const CAN_DELETE_PROGRESSIVE_PROFILING = 181

export const CAN_SEE_CONSENT_COLLECTIONS = 182
// Transaction Types
export const CAN_SEE_TRANSACTION_TYPES = 183
export const CAN_CREATE_UPDATE_TRANSACTION_TYPES = 184
export const CAN_DELETE_TRANSACTION_TYPES = 185
export const TRANSACTION_TYPES_FULL_PERMISSIONS = 186
// SSO Configuration
export const CAN_SEE_SSO_CONFIGURATION = 190
export const SSO_CONFIGURATION_FULL_PERMISSIONS = 191
