export const MANAGE_PREFERENCE_LINK = 'manage-preference-link'
export const MANAGE_PUBLIC_PORTAL_LINK = 'manage-public-portal-link'
export const PUBLIC_PORTAL_LINKS = 'public-portal-links'
export const PREFERENCE_LINKS = 'preference-links'
export const PREFERENCE_PAGE_LINKS = 'preference-page-links'
export const ACTION_LINKS = 'action-links'
export const MANAGE_LINK_TRANSLATION = 'manage-preference-link-translation'
export const CS_PORTAL_CONFIG = 'cs-portal-config'
export const CHANNELS = 'channels'
export const MANAGE_CHANNEL = 'manage-channel'
export const HEADERS = 'headers'
export const FOOTERS = 'footers'
export const CONNECTOR_SERVICE = 'connector-service'
export const MANAGE_INTEGRATION_SERVICE = 'manage-integration-service'
export const MANAGE_SYSTEM_INTEGRATION = 'manage-system-integration'
export const PROCESSING_PROCESSES = 'processing-processes'
export const PROCESSING_DASHBOARD = 'processing-dashboard'
export const DASHBOARD = 'dashboard'
export const PROCESSING_LOOK_UP_DATA = 'processing-look-up-data'
export const PROCESSING_SECURITY_MATRIX = 'processing-security-matrix'
export const PROCESSING_MANAGE_PROCESS = 'processing-manage-process'
export const CONFIRMATION_PAGES = 'confirmation-pages'
export const MANAGE_CONFIRMATION_PAGE = 'manage-confirmation-page'
export const SYSTEM_EMAILS = 'system-emails'
export const MANAGE_SYSTEM_EMAIL = 'manage-system-email'
export const MANAGE_SYSTEM_EMAIL_TRANSLATION = 'manage-system-email-translation'
export const THEMES = 'themes'
export const PREVIEW = 'preview'
export const DATA_LAKES_EXPORT = 'data-exports'
export const EXTENDED_PREFERENCES = 'extended-preferences'
export const MANAGE_EXTENDED_PREFERENCE_TRANSLATION = 'manage-extended-preference-translation'
export const ADMIN_PORTAL_USERS = 'admin-portal-users'
export const ADMIN_PORTAL_MANAGE_USER = 'admin-portal-manage-user'
export const ADMIN_PORTAL_API_USERS = 'admin-portal-api-users'
export const ADMIN_PORTAL_API_MANAGE_USER = 'admin-portal-api-manage-user'
export const WHITELISTING = 'whitelisting'
export const SENDER_EMAIL_ADDRESSES = 'sender-email-addresses'
export const BRANDS = 'brands'
export const LOG_VIEWER = 'log-viewer'
export const LANGUAGES = 'languages'
export const DATA_SUBJECT_RULES = 'data-subject-rules'
export const DATA_SUBJECT_GROUPING_RULES = 'data-subject-grouping-rules'
export const MANAGE_CHANNEL_TRANSLATION = 'manage-channel-translation'
export const PREFERENCE_WIDGETS = 'preference-widgets'
export const MANAGE_PREFERENCE_WIDGET = 'manage-preference-widget'
export const PRIVACY_POLICIES = 'privacy-policies'
export const MANAGE_PRIVACY_POLICY = 'manage-privacy-policy'
export const META_DATA_KEYS = 'meta-data-keys'
export const STATEMENT_GROUPS = 'statement-groups'
export const MANAGE_STATEMENT_GROUP = 'manage-statement-group'
export const ACCESS_KEYS = 'access-keys'
export const MANAGE_ACCESS_KEY = 'manage-access-key'
export const STATEMENTS = 'statements'
export const MANAGE_STATEMENT = 'manage-statement'
export const MANAGE_STATEMENT_TRANSLATION = 'manage-statement-translation'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD'
export const SESSION_EXPIRED = 'SESSION_EXPIRED'
export const COOKIE_CONSENT_POLICY = 'configure-cookie-policy-table'
export const SSO_ERROR = 'SSO_ERROR'
export const SSO_LOGOUT = 'SSO_LOGOUT'
export const DIRECT_LOGIN_NOT_ALLOWED = 'direct-login-not-allowed'
export const SITE_SCANNER = 'cookie-scanner'
export const SITE_SCANNER_REQUEST_SCAN = 'cookie-scanner-request-scan'
export const SITE_SCANNER_REPORT = 'cookie-scanner-report'
export const SITE_SCANNER_DOWNLOAD_COOKIE = 'cookie-scanner-download-cookie'
export const SITE_SCANNER_DOWNLOAD_ADVANCED_TRACKERS = 'cookie-scanner-download-advanced-trackers'
export const SITE_SCANNER_DOWNLOAD_SUB_RESOURCES = 'cookie-scanner-download-sub-resources'
export const SUPPORT_CREATE_TICKET = 'support-new'
export const SUPPORT_VIEW_TICKET = 'support-view'
export const PROGRESSIVE_PROFILING = 'progressive-profiling'
export const MANAGE_PROGRESSIVE_PROFILE = 'manage-progressive-profile'
export const ADMIN_PORTAL_PRODUCT_ROLES = 'admin-portal-product-roles'
export const COOKIE_CATEGORIES = 'cookie-categories'
export const COOKIE_SCRIPTS = 'cookie-scripts'
export const COOKIE_COLLECTION_TEMPLATES = 'cookie-collection-templates'
export const MANAGE_COOKIE_COLLECTION_TEMPLATE = 'manage-cookie-collection-template'
export const COOKIE_TEXT_TEMPLATES = 'cookie-text-templates'
export const MANAGE_COOKIE_TEXT_TEMPLATE = 'manage-cookie-text-template'
export const MANAGE_COOKIE_TEXT_TEMPLATE_TRANSLATION = 'manage-cookie-text-template-translation'
export const COOKIE_APPEARANCE_TEMPLATES = 'cookie-appearance-templates'
export const MANAGE_COOKIE_APPEARANCE_TEMPLATE = 'manage-cookie-appearance-template'
export const COOKIE_BANNERS = 'cookie-banners'
export const MANAGE_COOKIE_BANNER = 'manage-cookie-banner'
export const CHANNEL_DEPENDENCIES = 'channel-dependencies'
export const ACCOUNT_LOCKED_OUT = 'account-locked-out'
export const COOKIE_VENDORS = 'vendors'
export const CONSENT_STRUCTURE = 'consent-structure'
export const IAB_TEXT_MANAGEMENT = 'iab-text-management'
export const CONSENT_COLLECTION = 'consent-collection'
export const CONSENT_COLLECTION_WIDGETS = 'consent-collection-widgets'
export const MANAGE_CONSENT_COLLECTION_WIDGET = 'manage-consent-collection-widget'
export const CONSENT_COLLECTION_PREFERENCE_PAGES = 'consent-collection-preference-pages'
export const MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE = 'manage-preference-page'
export const TRANSACTION_TYPES = 'transaction-types'
export const MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION = 'manage-preference-page-translation'
export const MANAGE_CONSENT_COLLECTION_WIDGET_TRANSLATION = 'manage-consent-collection-widget-translation'
export const SSO_CONFIGURATION = 'sso-configuration'
export const MANAGE_SSO_CONFIGURATION = 'manage-sso-configuration'
